// Agify.js
import React, { useState } from 'react';

function Agify() {
  const [name, setName] = useState('');
  const [age, setAge] = useState(null);

  const fetchAge = async () => {
    if (name) {
      try {
        const response = await fetch(`https://api.agify.io/?name=${name}`);
        const data = await response.json();
        setAge(data.age);
      } catch (error) {
        console.error('Error fetching the age:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    setName(e.target.value);
    if (e.target.value === '') {
      setAge(null); // Reset age when input is cleared
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchAge();
    }
  };

  return (
    <div>
      <input
        type="text"
        value={name}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Enter a name"
      />
      <button onClick={fetchAge}>Get Age</button>
      {age && <p>The predicted age for {name} is {age}</p>}
    </div>
  );
}

export default Agify;
